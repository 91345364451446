import { Client, Databases, Query, Locale, Avatars } from 'node-appwrite';

const { VITE_APPWRITE_ENDPOINT, VITE_APPWRITE_PROJECT, VITE_APPWRITE_API_KEY } = import.meta.env;

const client = new Client();

client
	.setEndpoint(VITE_APPWRITE_ENDPOINT)
	.setProject(VITE_APPWRITE_PROJECT)
	.setKey(VITE_APPWRITE_API_KEY);

export const database = new Databases(client);
export const locale = new Locale(client);
export const avatars = new Avatars(client);

export const orderCollection = '625ef972b746a226f9ec';
export const jobCollection = '62724247689c3c5afc9f';
export const articleCollection = '62bc4b2b1c5a870064d9';
export const transactionCollection = '62bc46294d5bd81d0cc4';
export const locationCollection = '62bcb3c05555b7d8c58d';
export const invoiceCollection = '62f217c5cef4a4e546d2';
export const storageCollection = '62c3ede3ec3691bf3727';
export const defaultDatabaseId = 'default';

/**
 * @param {string} id
 * @returns {Promise<import("$utils/types").Order>}
 * */
export async function getOrder(id) {
	return database.getDocument(defaultDatabaseId, orderCollection, id);
}

/**
 *
 * @param {string} orderId
 * @param {boolean} archived
 * @returns {Promise<import("$utils/types").Job[]>}
 */
export async function getJobByOrderId(orderId, archived = false) {
	const response = await database.listDocuments(defaultDatabaseId, jobCollection, [
		Query.equal('orderId', orderId),
		Query.equal('archived', archived),
		Query.limit(100),
		Query.orderAsc('startDate')
	]);

	if (!response.documents?.length) return [];

	return response.documents;
}

/**
 *
 * @param {string[]} jobIds
 * @returns {Promise<import("$utils/types").Job[]>}
 */
export async function getJobs(jobIds = []) {
	if (!jobIds?.length) return [];

	const response = await database.listDocuments(defaultDatabaseId, jobCollection, [
		Query.equal('$id', jobIds),
		Query.equal('archived', false),
		Query.limit(100)
	]);

	if (!response.documents?.length) return [];

	return response.documents;
}

/**
 * @param {string} $id
 * @param {import("$utils/types").JobData} data
 * @returns {Promise<import("$utils/types").Job>}
 */
export async function syncJob($id, data) {
	if ($id) {
		return database.updateDocument(defaultDatabaseId, jobCollection, $id, data);
	}

	return database.createDocument(defaultDatabaseId, jobCollection, 'unique()', data);
}

/**
 *
 * @param {string[]} articles
 * @returns {Promise<import("$utils/types").Article[]>}
 */
export async function getArticles(articles) {
	if (!articles?.length) return [];

	const response = await database.listDocuments(defaultDatabaseId, articleCollection, [
		Query.equal('$id', articles)
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 *
 * @param {string} country
 * @returns {Promise<import("$utils/types").Article[]>}
 */
export async function getArticlesByCountry(country) {
	const response = await database.listDocuments(defaultDatabaseId, articleCollection, [
		Query.equal('active', true),
		Query.equal('country', country),
		Query.limit(100)
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 *
 * @param {string} id
 * @returns {Promise<import("$utils/types").Location>}
 */
export async function getLocation(id) {
	const location = await database.getDocument(defaultDatabaseId, locationCollection, id);

	return location;
}

/**
 *
 * @param {string[]} locations
 * @param {string[]} type
 * @returns {Promise<import("$utils/types").Location[]>}
 */
export async function getLocationsByType(locations = [], type = []) {
	if (!locations?.length) return [];

	const response = await database.listDocuments(defaultDatabaseId, locationCollection, [
		Query.equal('$id', locations),
		Query.equal('type', type),
		Query.equal('archived', false),
		Query.limit(100),
		Query.orderAsc('stop')
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 *
 * @param {string} orderId
 * @param {boolean} archived
 * @returns {Promise<import("$utils/types").Location[]>}
 */
export async function getLocationsByOrderId(orderId, archived = false) {
	const response = await database.listDocuments(defaultDatabaseId, locationCollection, [
		Query.equal('orderId', orderId),
		Query.equal('archived', archived),
		Query.limit(100),
		Query.orderAsc('stop')
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 *
 * @param {string} orderId
 * @param {string[]} types
 * @param {boolean} archived
 * @returns {Promise<import("$utils/types").Location[]>}
 */
export async function getLocationByOrderIdAndType(orderId, types, limit = 100, archived = false) {
	const response = await database.listDocuments(defaultDatabaseId, locationCollection, [
		Query.equal('orderId', orderId),
		Query.equal('type', types),
		Query.equal('archived', archived),
		Query.limit(limit),
		Query.orderAsc('stop')
	]);

	if (!response.documents?.length) return [];

	return response.documents;
}

/**
 *
 * @param {string} id
 * @param {import("$utils/types").LocationData} location
 * @returns {Promise<import("$utils/types").Location>}
 */
export async function createOrUpdateLocation(id, location) {
	if (!id) {
		return await database.createDocument(
			defaultDatabaseId,
			locationCollection,
			'unique()',
			location
		);
	} else {
		return await database.updateDocument(defaultDatabaseId, locationCollection, id, location);
	}
}

/**
 *
 * @param {string} id
 * @param {import("$utils/types").OrderData} order
 * @returns {Promise<import("$utils/types").Order>}
 */
export async function createOrUpdateOrder(id, order) {
	if (!id) {
		return await database.createDocument(defaultDatabaseId, orderCollection, 'unique()', order);
	} else {
		return await database.updateDocument(defaultDatabaseId, orderCollection, id, order);
	}
}

/**
 * @param {string} orderId
 * @param {boolean[]} archived
 * @returns {Promise<import("$utils/types").Invoice[]>}
 * */
export async function getInvoiceByOrderId(orderId, archived = [true, false]) {
	const response = await database.listDocuments(defaultDatabaseId, invoiceCollection, [
		Query.equal('orderId', orderId),
		Query.equal('archived', archived),
		Query.limit(100)
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 * @param {string} id
 * @returns {Promise<import("$utils/types").Invoice>}
 * */
export async function getInvoice(id) {
	const invoice = await database.getDocument(defaultDatabaseId, invoiceCollection, id);

	return invoice;
}

/**
 *
 * @param {string} orderId
 * @param {boolean[]} archived
 * @returns {Promise<import("$utils/types").Transaction[]>}
 */
export async function getTransactionByOrderId(orderId, archived = [true, false]) {
	if (!orderId) return [];

	const response = await database.listDocuments(defaultDatabaseId, transactionCollection, [
		Query.equal('orderId', orderId),
		Query.equal('archived', archived),
		Query.limit(100)
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 *
 * @param {string[]} jobIds
 * @param {boolean[]} archived
 * @returns {Promise<import("$utils/types").Transaction[]>}
 */
export async function getTransactionByJobId(jobIds, archived = [true, false]) {
	if (!jobIds?.length) return [];

	const response = await database.listDocuments(defaultDatabaseId, transactionCollection, [
		Query.equal('jobId', jobIds),
		Query.equal('archived', archived),
		Query.limit(100)
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 *
 * @param {string[]} storageIds
 * @param {boolean[]} archived
 * @returns {Promise<import("$utils/types").Transaction[]>}
 */
export async function getTransactionByStorageId(storageIds, archived = [true, false]) {
	if (!storageIds?.length) return [];

	const response = await database.listDocuments(defaultDatabaseId, transactionCollection, [
		Query.equal('storageId', storageIds),
		Query.equal('archived', archived),
		Query.limit(100)
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 * @param {string} orderId
 * @returns {Promise<import("$utils/types").Storage[]>}
 * */
export async function getStorageByOrderId(orderId, archived = [true, false]) {
	if (!orderId) return [];

	const response = await database.listDocuments(defaultDatabaseId, storageCollection, [
		Query.equal('orderId', orderId),
		Query.equal('archived', archived),
		Query.limit(100)
	]);

	if (!response.documents) return [];

	return response.documents;
}

/**
 *
 * @param {string} $id
 * @param {import("$utils/types").Storage | import("$utils/types").StorageData} data
 * @returns {Promise<import("$utils/types").Storage>}
 */
export async function syncStorage($id, data) {
	if ($id) {
		return database.updateDocument(defaultDatabaseId, storageCollection, $id, data);
	}

	return database.createDocument(defaultDatabaseId, storageCollection, 'unique()', data);
}

import allBookingOptions from '$utils/bookingOptions.json';
import {
	differenceInHours,
	format,
	lightFormat,
	parseISO,
	parse,
	isValid,
	subHours
} from 'date-fns';
import { camelCase, groupBy, head, reduce, round, sortBy, uniqBy } from 'lodash-es';
import { getArticles, getLocationByOrderIdAndType, getOrder, locale } from './appwrite';

export const acceptedCountryCodes = ['SE', 'FI', 'NO', 'DK', 'DE'];
export const acceptedCountry = ['se', 'fi', 'en'];

export async function getPhoneCodeOptions() {
	const phoneCodeOptions = await locale
		.listCountriesPhones()
		.then((options) =>
			options.phones.filter(({ countryCode }) => acceptedCountryCodes.includes(countryCode))
		);

	return phoneCodeOptions;
}

/**
 *
 * @param {Request} request
 * @param {{stringify?: string[], numberify?: string[], floatify?: string[], booleanify?: string[], jsonify?: string[], dateify?: string[]}} [config]
 * @returns {Promise<object>}
 */
export async function parseFormData(request, config = {}) {
	const {
		stringify = [],
		numberify = [],
		booleanify = [],
		floatify = [],
		jsonify = [],
		dateify = []
	} = config;

	const form = await request.formData();
	/** @type {object} */
	const data = Object.fromEntries(form);
	const formatted = reduce(
		data,
		(obj, value, key) => {
			if (stringify.includes(key)) {
				return {
					...obj,
					[key]: `${value}`
				};
			} else if (numberify.includes(key)) {
				return {
					...obj,
					[key]: parseInt(value || 0)
				};
			} else if (booleanify.includes(key)) {
				return {
					...obj,
					[key]: !!value
				};
			} else if (floatify.includes(key)) {
				return {
					...obj,
					[key]: parseFloat(value)
				};
			} else if (jsonify.includes(key)) {
				return {
					...obj,
					[key]: JSON.parse(value)
				};
			} else if (dateify.includes(key)) {
				return {
					...obj,
					[key]: parseISO(value)
				};
			} else {
				return {
					...obj,
					[key]: isNaN(value) ? value : value ? parseInt(value) : undefined
				};
			}
		},
		{}
	);

	return formatted;
}

/**
 *
 * @param {string} pageKey
 * @param {string} lang
 * @returns {array}
 */
export function getBookingOptions(pageKey, lang) {
	const options = allBookingOptions
		.filter(({ page }) => page === pageKey)
		.map((furniture) => translate(furniture, lang))
		.sort((a, b) => a.order - b.order)
		.reduce(buildGroups, {});

	return options;
}

/**
 *
 * @param {object} obj
 * @param {object} val
 * @returns {object}
 */
export function buildGroups(obj, val) {
	const data = obj?.[val.group] ?? [];

	return {
		...obj,
		[val.group]: [...data, val]
	};
}

/**
 *
 * @param {object} data
 * @param {string} lang
 * @returns {object}
 */
export function translate(data, lang) {
	return {
		...data,
		name: camelCase(data.group),
		text: data.title?.[lang],
		description: data.description?.[lang],
		size: data.size?.[lang]
	};
}

export function formatArticle(lang) {
	return (data) => ({
		...data,
		name: camelCase(data.type),
		text: JSON.parse(data.title)?.[lang] ?? '',
		description: JSON.parse(data.description)?.[lang] ?? '',
		value: data.$id
	});
}

/**
 *
 * @param {Array} articles
 * @param {string} lang
 * @returns {Array<{Packing: Array, Cleaning: Array, Storage: Array}>}
 */
export function formatArticles(articles, lang) {
	const format = formatArticle(lang);

	const articlesTranslated = articles.map(format);

	const articlesSorted = sortBy(articlesTranslated, ['sortOrder']);

	const groupedArticlesByType = groupBy(articlesSorted, ({ type }) => type);

	return groupedArticlesByType;
}

/**
 *
 * @param {string} building
 * @param {object} translations
 * @returns {string}
 */
export function translateBuilding(building, translations) {
	if (building === 'Apartment') return translations.apartment;
	if (building === 'House') return translations.house;
	if (building === 'Office') return translations.office;
	if (building === 'Storage') return translations.storageTitle;
}

/**
 *
 * @param {string} orderId
 * @returns {Promise<object>}
 */
export async function getOrderSummary(orderId) {
	const order = await getOrder(orderId);
	const articles = await getArticles(order.articles);
	const locations = await getLocationByOrderIdAndType(orderId, ['From', 'To']);
	const uniqLocations = uniqBy(locations, 'type');

	const {
		$id,
		status,
		name,
		phone,
		email,
		personalId,
		requestedDate,
		requestedTime,
		requestedStorageDateOut,
		rut,
		additionalInfo,
		country,
		customerType,
		totalM2
	} = order;

	const date = safeDateParseAndFormat(requestedDate, 'd/M');
	const storageDate = safeDateParseAndFormat(requestedStorageDateOut, 'd/M');

	const requestedPacking = !!articles.find(({ type }) => type === 'Packing');
	const requestedCleaning = !!articles.find(({ type }) => type === 'Cleaning');
	const requestedStorage = !!articles.find(({ type }) => type === 'Storage');

	const fromLocation = locations?.find(({ type }) => type === 'From');
	const totalM3 = calculateM3(order, fromLocation);

	return {
		$id,
		orderId,
		status,
		name,
		customerType,
		country,
		phone,
		email,
		personalId,
		totalM2,
		totalM3,
		articles,
		locations: uniqLocations,
		date,
		storageDate,
		requestedDate,
		requestedTime,
		rut,
		additionalInfo,
		requestedPacking,
		requestedCleaning,
		requestedStorage
	};
}

/**
 * @param {Array<string>} furnitures
 * @returns {Array<string>}
 */
export function filterFurnitures(furnitures) {
	const filteredFurnitures = furnitures.filter((furniture) =>
		allBookingOptions.map(({ value }) => value).includes(furniture)
	);

	return filteredFurnitures;
}

/**
 *
 * @param {number} total
 * @param {string} furniture
 * @returns {number}
 */
export function sumFurnitureM3(total, furniture) {
	const foundFurniture = allBookingOptions.find(({ value }) => value === furniture)?.m3 ?? 0;

	return total + foundFurniture;
}

export function sumFurnituresM3(furnitures, boxes = 0) {
	const furnitureM3 = furnitures.reduce(sumFurnitureM3, 0);
	const boxesM3 = boxes * allBookingOptions.find(({ value }) => value === 'box')?.m3 ?? 0;

	const totalM3 = ceil(furnitureM3 + boxesM3);

	return totalM3;
}

/**
 * @param {import("$utils/types").Order} order
 * @param {import("$utils/types").Location} location
 * @returns {number}
 */
export function calculateM3(order, location) {
	const { furnituresEstimate, furnitures, boxes, totalM2 } = order;

	if (!furnituresEstimate || furnituresEstimate === 'notFully') {
		const totalM3 = sumFurnituresM3(furnitures, boxes);

		return totalM3;
	} else {
		const specialFurnitures = furnitures.filter((val) =>
			['sensitive', 'tricky', 'heavy'].includes(val)
		);

		const furnitureM3 = sumFurnituresM3(specialFurnitures);

		const multiplier = furnituresEstimate === 'more' ? 1.3 : 1;

		const baseM3 = location?.building === 'Storage' ? totalM2 * 2 : totalM2 / 3;

		const totalM3 = ceil((baseM3 + furnitureM3) * multiplier, 1);

		return totalM3;
	}
}

export function getTimeOptions(translations) {
	const timeOptions = [
		{
			text: translations.anytime,
			value: 'Any'
		},
		{
			text: '8:00',
			value: '8:00'
		},
		{
			text: '13:00',
			value: '13:00'
		}
	];

	return timeOptions;
}

export const floorOptions = [
	{ value: 0, text: '0' },
	{ value: 1, text: '1' },
	{ value: 2, text: '2' },
	{ value: 3, text: '3' },
	{ value: 4, text: '4' },
	{ value: 5, text: '5' },
	{ value: 6, text: '6' },
	{ value: 7, text: '7+' }
];

export function roundPrice(currency, price) {
	return round(price, currency === 'EUR' ? 2 : 0);
}

export function ceil(value, step) {
	step || (step = 1.0);

	const inv = 1.0 / step;

	return Math.ceil(value * inv) / inv;
}

export function scrollToOnClick(node) {
	const doScroll = () => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);

		if (isMobile) {
			node.scrollIntoView();
			node.focus();
		}
	};

	node.addEventListener('click', doScroll);

	return {
		destroy() {
			node.removeEventListener('click', doScroll);
		}
	};
}

export function translateType(translations, type) {
	if (type === 'Move') return translations.moveTitle;

	if (type === 'Long Distance') return translations.longDistanceTitle;

	if (type === 'Packing') return translations.packingTitle;

	if (type === 'Delivery') return translations.deliveryTitle;

	if (type === 'Cleaning') return translations.cleaningTitle;

	if (type === 'Storage') return translations.storageTitle;

	if (type === 'Material') return translations.materialBoxesTitle;

	return translations.otherTitle;
}

export function getContactLink(lang) {
	const contactLink =
		lang === 'se'
			? 'https://grabbarnaflytt.notion.site/V-lkommen-till-Grabbarna-Flytt-1b38ce7170ea43fa962a7897b9fc198d'
			: lang === 'fi'
			? 'https://grabbarnaflytt.notion.site/Tervetuloa-Grabbarna-Flyttille-7f50cc9cc217487d90d640257da8a96e'
			: 'https://grabbarnaflytt.notion.site/Welcome-to-Grabbarna-Flytt-295daa79cb394a03a4ab20689716e2df';

	return contactLink;
}

export function formatJobTime(startDate, endDate) {
	const startParsed = parseISO(startDate);
	const endParsed = parseISO(endDate);
	const startTime = format(startParsed, 'H:mm');
	const endTime = format(endParsed, 'H:mm');
	const hours = differenceInHours(endParsed, startParsed);
	const sub = Math.ceil(hours / 4);

	let estimatedHoursStart = differenceInHours(subHours(endParsed, sub), startParsed);
	let estimatedHours =
		hours && estimatedHoursStart === hours
			? `${hours}`
			: hours && hours <= 12
			? `${estimatedHoursStart}-${hours}h`
			: '';

	return {
		startTime,
		endTime: hours < 24 ? endTime : '',
		estimatedHours
	};
}

export function safeDateParseAndFormat(date, formatString) {
	if (!date) return date;

	let parsedDate;

	try {
		parsedDate = parseISO(date);
	} catch (e) {
		throw new Error(`Could not safe parse and format date: ${date} -> ${e?.message ?? ''}`);
	}

	if (!isValid(parsedDate)) {
		parsedDate = parse(date, 'yyyy-dd-MM', new Date());
	}

	const formattedDate = lightFormat(parsedDate, formatString);

	return formattedDate;
}

export function formatOrderToGTMEcomData(order, transactions) {
	const baseData = {
		...order,
		transaction_id: order.$id,
		value: 0
	};

	if (!transactions?.length) return baseData;

	const currency = head(transactions)?.currency;
	const value = transactions.reduce(
		(sum, { fixedPrice = 0, transportCost = 0, unit, price, amount }) => {
			if (unit === 'pc') return sum + price * amount;

			return sum + fixedPrice + transportCost;
		},
		0
	);
	const items = transactions.map(
		({ articleId, name, category, type, price, fixedPrice, transportCost, amount, unit }) => ({
			item_id: articleId,
			item_name: name,
			item_category: category,
			item_category2: type,
			item_category3: unit,
			price: unit === 'pc' ? price : fixedPrice + transportCost,
			quantity: unit === 'pc' ? amount : 1
		})
	);

	return {
		...baseData,
		currency,
		value,
		items
	};
}

export function splitStringIntoChunks(str, chunkSize) {
	let chunks = [];

	if (!str?.length) return chunks;

	for (let i = 0; i < str.length; i += chunkSize) {
		chunks.push(str.substring(i, i + chunkSize));
	}

	return chunks;
}
